<template>
    <main class="page_bg_grey blank-aside-js min-h-100vh w-100" :class = "(isOpenAside=='true')?'blank-aside':''">
        <SuppliersPage
            banner_location="communications_home-top"
            supplier_router="CommunicationSupplier"
            product_router="CommunicationProduct"
            page_title="prepaid"
        ></SuppliersPage>
    </main>
</template>
  
<script>
    import SuppliersPage from '@/components/endUser/suppliersPages/SuppliersPage.vue';

    export default {
        name: 'Communication',
        components: {
            SuppliersPage
        },
    }
</script>
